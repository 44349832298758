<template>
	<div>
		<b-modal centered id="reinjection-details" title="Float Details">
			<b-container v-if="details" fluid>
				<b-row>
					<b-col cols="3">
						<p>Branch:</p>
					</b-col>
					<b-col cols="9">
						<p class="font-weight-bold text-right">
							{{ details.branch.name }}
						</p>
					</b-col>
					<b-col cols="6">
						<p>Float Amount:</p>
					</b-col>
					<b-col cols="6">
						<p class="font-weight-bold text-right text-success">
							{{ formatMoney(details.additionalFloat) }}
						</p>
					</b-col>
					<!-- <b-col cols="6">
						<p>Confirmed by Branch:</p>
					</b-col>
					<b-col cols="6">
						<h4 class="text-right my-0">
							<b-badge
								class="font-weight-light"
								:variant="details.confirmed ? 'success' : 'danger'"
							>
								{{ details.confirmed ? "Yes, Confirmed" : "Not Confirmed" }}
							</b-badge>
						</h4>
					</b-col>
					<b-col cols="6">
						<p>Confirmed by Bearer:</p>
					</b-col>
					<b-col cols="6">
						<h4 class="text-right my-0">
							<b-badge
								class="font-weight-light"
								:variant="details.completed ? 'success' : 'danger'"
							>
								{{ details.completed ? "Yes, Confirmed" : "Not Confirmed" }}
							</b-badge>
						</h4>
					</b-col> -->
					<b-col cols="6">
						<p>Date Sent:</p>
					</b-col>
					<b-col cols="6">
						<p class="font-weight-bold text-right">
							{{ details.transactionDate }}
						</p>
					</b-col>
				</b-row>
			</b-container>

			<!-- <template #modal-footer>
				<b-button v-b-modal.wap-modal variant="success">
					WhatsApp Link</b-button
				>
			</template> -->
		</b-modal>

		<!-- Whatsapp modal -->
		<b-modal centered title="WhatsApp Link" id="wap-modal" hide-footer>
			<p>Please enter the number of the person to send the link to</p>
			<b-form-group label="Phone number">
				<b-input
					v-model="phone"
					type="number"
					placeholder="18765550088"
				></b-input>
			</b-form-group>
			<b-button @click="sendWhatsAppLink" variant="success" size="sm"
				>Send Message</b-button
			>
		</b-modal>
	</div>
</template>

<script>
	export default {
		props: {
			details: Object,
		},
		data() {
			return {
				phone: null,
				confirmationLink: "",
			};
		},
		methods: {
			formatMoney(money) {
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(money);
			},
			sendWhatsAppLink() {
				if (!this.phone || (this.phone && this.phone.length < 10))
					return this.$bvToast.toast("Invalid phone number", {
						title: "Error",
						variant: "danger",
					});
				const encodedString = encodeURIComponent(`Good Day,

Here is the link that should be used to confirm once you have delivered the cash to the branch manager:
${window.location.origin}/complete-transaction/${this.details._id}

Click on this link to confirm that the money was delivered. *Call me if you have any questions.*`);
				const url = `https://wa.me/${this.phone}?text=${encodedString}`;
				window.open(url, "_blank");
				this.$bvModal.hide("wap-modal");
				this.phone = null;
			},
		},
	};
</script>

<style></style>
