<template>
	<div>
		<b-modal
			@hidden="clearForm"
			no-close-on-backdrop
			no-close-on-esc
			size="lg"
			hide-footer
			id="add-reinjection"
			:title="isEditing ? 'Edit Float' : 'Send Float'"
			centered
		>
			<p class="text-muted">
				{{
					isEditing
						? "Update float for the selected branch"
						: "Send float to the selected branch"
				}}
			</p>
			<b-overlay :show="showLoader">
				<b-form @submit.prevent="createReinjection">
					<b-container class="px-0" fluid>
						<b-row>
							<b-col cols="12" md="4">
								<b-form-group
									description="Select the branch"
									label-for="re-branch"
									label="Branch"
								>
									<b-form-select
										required
										value-field="_id"
										text-field="name"
										:options="branches"
										v-model="branch"
										id="re-branch"
									></b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group
									description="Enter the float amount"
									label-for="re-amount"
									label="Amount"
								>
									<b-input
										required
										v-model.number="additionalFloat"
										id="re-amount"
										type="number"
										min="1"
									></b-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group
									description="Today's date"
									label-for="transactionDate"
									label="Date"
								>
									<b-input
										required
										v-model="transactionDate"
										id="transactionDate"
										type="date"
									></b-input>
								</b-form-group>
							</b-col>
							<b-col v-if="branch" cols="12">
								<p>
									Use this section to
									<strong class="text-info">"back date"</strong> a float
									reinjection, i.e For a reading that took place in the past.
								</p>
							</b-col>

							<b-col v-if="branch" cols="12" md="6">
								<b-form-group
									description="Select the month that the past transaction happened"
									label-for="startDate"
									label="Date"
								>
									<b-input
										v-model="filterStartDate"
										id="startDate"
										type="month"
									></b-input>
								</b-form-group>
							</b-col>
							<b-col v-if="branch" cols="12" md="6">
								<b-form-group
									description="Select the exact date that the reading was done"
									label-for="actualReading"
									label="Transaction Reading Date"
								>
									<b-select
										:options="pastTransactions"
										v-model="dateReflected"
										id="actualReading"
									></b-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label-for="re-comment" label="Comment">
									<b-form-textarea
										v-model="comments"
										id="re-comment"
										rows="3"
									></b-form-textarea>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-button variant="success" type="submit">Submit</b-button>
							</b-col>
						</b-row>
					</b-container>
				</b-form>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import { mapActions } from "vuex";
	export default {
		props: {
			branches: Array,
			submitFn: Function,
		},
		data() {
			return {
				isEditing: false,
				id: null,
				branch: null,
				additionalFloat: 1,
				comments: null,
				readingType: "FLOAT-REINJECTION",
				showLoader: false,
				transactionDate: dayjs().format("YYYY-MM-DD"),
				filterStartDate: null,
				dateReflected: null,
				pastTransactions: [],
			};
		},
		watch: {
			branch: function (v) {
				if (v) {
					this.loadPastTransactions();
				}
			},
			filterStartDate: function (v) {
				if (v) {
					this.loadPastTransactions();
				}
			},
		},
		methods: {
			...mapActions("transactionsModule", [
				"getPastTransactionDates",
				"updateTransaction",
			]),
			// Function used to get the list of past transaction dates
			// Based on the branch selected & the filterStartDate props
			async loadPastTransactions() {
				if (!this.filterStartDate || !this.branch) return;
				const { data } = await this.getPastTransactionDates({
					branchId: this.branch,
					startDate: dayjs(this.filterStartDate)
						.startOf("month")
						.format("YYYY-MM-DD"),
					endDate: dayjs(this.filterStartDate)
						.endOf("month")
						.format("YYYY-MM-DD"),
				});
				if (!this.isEditing) this.dateReflected = null;
				if (!Array.isArray(data)) return;
				this.pastTransactions = data;
			},
			// Method used to reset form fields
			clearForm() {
				this.isEditing = false;
				this.branch =
					this.comments =
					this.filterStartDate =
					this.dateReflected =
						null;
				this.additionalFloat = 1;
				this.transactionDate = dayjs().format("YYYY-MM-DD");
				this.pastTransactions = [];
			},
			// Method used to set float for edit
			async setEdit(item) {
				this.isEditing = true;
				this.id = item._id;
				this.branch = item.branch._id;
				this.additionalFloat = item.additionalFloat;
				this.comments = item.comments;
				this.transactionDate = dayjs(item.transactionDate).format("YYYY-MM-DD");
				this.filterStartDate = dayjs(item.transactionDate)
					.startOf("month")
					.format("YYYY-MM");

				await this.loadPastTransactions();
				this.dateReflected = item.dateReflected;
			},
			async createReinjection() {
				//Validate
				if (
					!this.branch ||
					!this.additionalFloat ||
					(this.additionalFloat && this.additionalFloat < 1)
				) {
					return this.$bvToast.toast("Please enter all required fields", {
						title: "Missing Data",
						autoHideDelay: 5000,
						variant: "danger",
					});
				}

				try {
					this.showLoader = true;
					if (!this.isEditing) {
						const data = await this.submitFn({
							branch: this.branch,
							additionalFloat: this.additionalFloat,
							comments: this.comments,
							readingType: "FLOAT-REINJECTION",
							transactionDate: this.transactionDate,
							dateReflected: this.dateReflected,
						});
						this.$emit("FloatCreated", data);
					} else {
						//Update
						const data = await this.updateTransaction({
							_id: this.id,
							comments: this.comments,
							additionalFloat: this.additionalFloat,
							transactionDate: this.transactionDate,
							dateReflected: this.dateReflected,
						});
						this.$emit("FloatCreated", data);
					}
					this.showLoader = false;
					//Show success message to user
					this.$bvToast.toast(
						`A float amount of ${this.additionalFloat} was ${
							this.isEditing ? "updated" : "created"
						}.`,
						{
							title: `Float ${this.isEditing ? "Updated" : "Created"}`,
							autoHideDelay: 7000,
							variant: this.isEditing ? "info" : "success",
						}
					);
					this.$bvModal.hide("add-reinjection");
				} catch (error) {
					return this.$bvToast.toast(error.response.data, {
						title: "Error",
						autoHideDelay: 7000,
						variant: "danger",
					});
				}
			},
		},
	};
</script>

<style></style>
