<template>
	<Layout>
		<PageHeader :title="title" :items="breadCrumb" />
		<b-card>
			<b-row>
				<b-col>
					<h4 class="header-title">Float Reinjections</h4>
				</b-col>
				<b-col class="text-right">
					<b-button @click="downloadFloat" class="mr-3" size="sm" variant="outline-primary"
						>Export Float Data</b-button
					>
					<b-button v-b-modal.add-reinjection class="btn btn-success btn-sm">Create Float</b-button>
				</b-col>
			</b-row>
			<p class="sub-header">Table showing all the float reinjections created</p>
			<b-input placeholder="Search here..." v-model="search" />
			<br />
			<b-table
				thead-class="red-t-head"
				:filter="search"
				outlined
				:per-page="perPage"
				:current-page="currentPage"
				hover
				:fields="headers"
				:items="floatReinjections"
			>
				<template #cell(actions)="row">
					<span role="button" @click="displayFloatCreatedModal(row.item)">
						<i class="mdi mdi-18px mdi-eye"></i>
					</span>
					<span class="ml-2" role="button" @click="setFloatToEdit(row.item)">
						<i class="mdi mdi-18px mdi-pencil"></i>
					</span>
				</template>
			</b-table>
			<b-pagination
				:total-rows="floatReinjections && floatReinjections.length"
				:per-page="perPage"
				v-model="currentPage"
			></b-pagination>
		</b-card>
		<AddReinjection
			ref="addFloatModal"
			@FloatCreated="
				displayFloatCreatedModal;
				getAllTransactions();
			"
			:submitFn="createReinjection"
			:branches="branches"
		/>
		<FloatDetails :details="floatDetails" />
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import dayjs from "dayjs";
	import PageHeader from "@/components/Page-header.vue";
	import AddReinjection from "@/components/modals/AddReinjection.vue";
	import FloatDetails from "@/components/modals/ViewReinjectionDetails.vue";
	import { mapState, mapActions, mapGetters } from "vuex";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	import { cloneDeep } from "lodash";
	export default {
		components: {
			Layout,
			PageHeader,
			AddReinjection,
			FloatDetails,
		},
		page: {
			title: "Float Reinjection",
		},
		async created() {
			await this.getBranches();
			await this.getAllTransactions();
		},
		data() {
			return {
				title: "Float Reinjection",
				floatDetails: null,
				transactions: [],
				breadCrumb: [
					{
						text: "Float",
						active: true,
					},
				],
				//Float table headers
				currentPage: 1,
				perPage: 10,
				search: "",
				headers: [
					{
						label: "Branch",
						key: "branch.name",
						sortable: true,
					},
					{
						label: "Amount",
						key: "additionalFloat",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Date Sent",
						key: "transactionDate",
						sortable: true,
						formatter: (v) => {
							return dayjs(v).format("MMMM DD, YYYY");
						},
					},
					{
						label: "Actions",
						key: "actions",
					},
				],
			};
		},
		methods: {
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("transactionsModule", [
				"createReinjection",
				"getTransactions",
				"exportAdditionalFloat",
			]),

			// Method used to download float data
			async downloadFloat() {
				try {
					await this.exportAdditionalFloat();
				} catch (error) {
					console.log(error);
				}
			},
			async getAllTransactions() {
				const { data } = await this.getTransactions({
					readingType: "FLOAT-REINJECTION",
				});
				this.transactions = data.results;
				//sort by date transactionDate in desc order
				this.transactions.sort((a, b) => {
					return new Date(b.transactionDate) - new Date(a.transactionDate);
				});
			},
			async displayFloatCreatedModal(data) {
				this.floatDetails = JSON.parse(JSON.stringify(data));
				this.$bvModal.show("reinjection-details");
			},
			async setFloatToEdit(data) {
				this.floatDetails = JSON.parse(JSON.stringify(data));
				this.$refs.addFloatModal.setEdit(cloneDeep(data));
				this.$bvModal.show("add-reinjection");
			},
		},
		computed: {
			...mapState("branchModule", ["branches"]),
			floatReinjections() {
				return this.transactions.filter((t) => t.readingType == "FLOAT-REINJECTION");
			},
		},
	};
</script>

<style></style>
